export default {
  word1001: 'English',
  word1002: 'Email',
  word1003: 'Password',
  word1004: 'Forget password？',
  word1005: 'Login',
  word1006: 'Register',
  word1007: 'User Agreement',
  word1008: 'Privacy',
  word1009: 'About Us',
  word1010: 'Please enter the account',
  word1011: 'Please enter the password',
  word1012: 'The account has been frozen',
  word1013: 'Incorrect account name or password',
  word1014: 'Successful Login',
  word1015: 'Real name',
  word1016: 'Please enter the numbers on the right',
  word1017: 'Get Verification Code',
  word1018: 'Please re-enter your new password',
  word1019: 'Please enter the numbers on the right',
  word1020: 'Incorrect numbers',
  word1021: 'The entries do not match. Please re-enter',
  word1022: 'Please enter your real name',
  word1023: 'Please enter the Email address',
  word1024: 'Please re-enter your new password',
  word1025: ' s to resend',
  word1026: 'Registration succeeded',
  word1027: 'Please enter the Email verification code',
  word1028: 'Email verification code cannot be empty',
  word1029: 'New Password',
  word1030: 'Confirm',
  word1031: 'Please enter the new password',
  word1032: 'Modification Successful',
  word1033: 'Xiaoxiang Foreign Trade System',
  word1034: 'Hunan Xiaoxiang Trade',
  word1035: 'Your account has been banned',
  word1036: 'Register and Verify',
  word1037: 'Company name',
  word1038: 'Company address',
  word1039: 'Company phone number',
  word1040: 'Position Held',
  word1041: 'Upload photo / scanned copy of Business License',
  word1042: 'JPG/PNG only, and must not exceed 20MB.',
  word1043: 'Upload photo / scanned copy of Tax Certificate',
  word1044: 'Submit',
  word1045: 'Skip verifying now, proceed to browse.',
  word1046: 'Image preview.',
  word1047: 'Upload photo / scanned copy of Business License',
  word1048: 'Upload photo / scanned copy of Tax Certificate',
  word1049: 'Please enter the company name.',
  word1050: 'Please enter the company address.',
  word1051: 'Company phone number cannot be empty',
  word1052: 'Please enter your occupation.',
  word1053: 'Deleted successfully.',
  word1054: 'Submitted successfully.',
  word1055: 'Sorry, this email has already been registered. Please use another email.',
  word1056: 'Register Directly Without Verification',
  word1057:
    'This system is dedicated to cross-border interconnection, and we suggest you verify your company to enjoy more business opportunities and legal resources.',
  word1058: 'Notice',
  word1059: 'Skip verification',
  word1060: 'Go to Verify',
  word1061: 'User email already exists or has been deleted',
  word1062: 'Password length should be between 8 and 16 characters',
  word1063: 'Incorrect email format',
  word1064: 'Invalid email verification code, please obtain again',
  word1065: 'Incorrect email verification code',
  word1070: 'Resend',
  AgainInputPassword: 'Please re-enter your password',
  AccountInexistence: 'The account does not exist, and the password cannot be modified.',
  DeleteTip: 'Your account has been deleted. Please contact your Chamber of Commerce administrator.',
  DearUser: 'Dear user,',
  NotShow: 'No longer displayed',
  PromptContentOne: 'Hello, this system is ',
  PromptContentTwo: ' for enterprise members to create an internal IT system, aggregate ',
  PromptContentThree:
    ' domestic and foreign business information and legal resources for enterprise use, suitable for enterprise members shareholders, general managers, marketing, sales, purchasing department, legal department in charge of the use. Please confirm that your unit is a member of the ',
  PromptContentFour:
    ' before registration, and truthfully fill in your personal information. Please note that our background management system will freeze or delete non-member accounts.',
  PromptContentFive: 'Thank you for your attention ',
  PromptContentSix: ' and thank you for your support!',
  PromptContentSeven: ' Secretariat',
  QuickPaymentTip:
    'Your chamber of commerce has set a membership fee payment. Please settle it promptly, or you will face consequences by [specific date]',
  UnableLoginTip: 'Unable to log in',
  Prompt: 'Prompt',
  MembershipDues: 'Pay membership fee',
  Determine: 'Confirm',
  MembershipExpirationTip: 'Your membership has expired. Please renew by paying the membership fee',
  Cancel: 'Cancel',
  ImgOnly: 'The image format can only be jpeg、jpg、png、bmp、tiff',
  PictureSize2MBTip: 'The image size cannot exceed 2MB',
  UploadSuccess: 'Uploaded successfully'
}
